import styled from '@emotion/styled'
import 'animate.css'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import React from 'react'
import Client from 'shopify-buy'
import 'swiper/css'

import StoreContext, { defaultStoreContext } from '../context/StoreContext'
import '../styles/all.css'
import '../styles/app.css'
import '../styles/app.sass'
import '../styles/main.min.css'
import '../styles/reviews.css'
import '../styles/slick-theme.min.css'
import '../styles/slick.min.css'

const locales = require('../constants/locales')

const OOSData = {
  US: {
    'COPB01-NAV': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'COPB02-CHA': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'COPB03-FOR': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'COPB04-NUD': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'COPB05-BUR': {
      avaliable: true,
      personalisation: false,
      count: 7,
    },
    'COPB06-MON': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'COPB07-CLA': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'COPB07-CLY': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'COPB08-SKY': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'COPB09-SHA': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'COPB09-SHW': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'COPB10-GRY': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'COPB11-OLI': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'COPB12-PIN': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'COPB13-YEL': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'COPB14-BLU': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'CHK01-NAV': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'CHK02-CHA': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'CHK03-FOR': {
      avaliable: true,
      personalisation: true,
      count: 3,
    },
    'CHK04-NUD': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'CHK05-BUR': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'CHK06-MON': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'CHK07-CLA': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'CHK07-CLY': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'CHK08-SKY': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'CHK09-SHA': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'CHK09-SHW': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'CHK10-GRY': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'CHK11-OLI': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'CHK12-PIN': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'CHK13-YEL': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'CHK14-BLU': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'PLS01-NAV': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'PLS02-CHA': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'PLS03-FOR': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'PLS04-NUD': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'PLS05-BUR': {
      avaliable: false,
      personalisation: true,
      count: 1,
    },
    'PLS06-MON': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'PLS07-CLA': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'PLS07-CLY': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'PLS08-SKY': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'PLS09-SHA': {
      avaliable: true,
      personalisation: true,
      count: 15,
    },
    'PLS09-SHW': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'PLS11-OLI': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'PLS13-YEL': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'PLS14-BLU': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'CME01-NAV': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'CME02-CHA': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'CME03-FOR': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'CME04-NUD': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'CME05-BUR': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'CME06-MON': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'CME07-CLA': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'CME08-SKY': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'CME09-SHA': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'CAR-WKD01-NAV': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'CAR-WKD02-BLA': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'WKD01-NAV': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'WKD02-CHA': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'WKD03-FOR': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'WKD04-NUD': {
      avaliable: false,
      personalisation: true,
      count: 1,
    },
    'BPK01-NAV': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'BPK02-CHA': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'BPK03-FOR': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'BPK04-NUD': {
      avaliable: true,
      personalisation: true,
      count: 1,
    },
    'CAR-BPK01-NAV': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'CAR-BPK02-BLA': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'CAR-BPK02-CHA': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'CAR-TOT01-NAV': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'CAR-TOT02-BLA': {
      avaliable: true,
      personalisation: true,
      count: 2,
    },
    'TOT01-NAV': {
      avaliable: true,
      personalisation: true,
      count: 7,
    },
    'TOT02-CHA': {
      avaliable: true,
      personalisation: true,
      count: 3,
    },
    'TOT03-FOR': {
      avaliable: true,
      personalisation: true,
      count: 17,
    },
    'TOT04-NUD': {
      avaliable: true,
      personalisation: true,
      count: 10,
    },
    '4PC01-SNO': {
      avaliable: true,
      personalisation: true,
      count: 1,
    },
    '4PC02-CHA': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    '4PC03-FOR': {
      avaliable: true,
      personalisation: true,
      count: 1,
    },
    '4PC04-CLY': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    '4PC05-SKY': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    '4PC06-SHW': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    '8PC01-SNO': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    '8PC02-CHA': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    '8PC03-FOR': {
      avaliable: true,
      personalisation: true,
      count: 1,
    },
    '8PC04-CLY': {
      avaliable: true,
      personalisation: false,
      count: 11,
    },
    '8PC05-SKY': {
      avaliable: true,
      personalisation: false,
      count: 14,
    },
    '8PC06-SHW': {
      avaliable: false,
      personalisation: true,
      count: 2,
    },
    'PRO-COPB01-NAV': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'PRO-COPB02-CHA': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'PRO-COPB03-FOR': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'PRO-COPB04-MON': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'CAR-DPK01-NAV': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'CAR-DPK02-BLA': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'CAR-DPK03-FOR': {
      avaliable: true,
      personalisation: true,
      count: 8,
    },
    'CAR-DPK04-CHK': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'CAR-SLI01-NAV': {
      avaliable: true,
      personalisation: true,
      count: 18,
    },
    'CAR-SLI02-BLA': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'CAR-SLI03-FOR': {
      avaliable: true,
      personalisation: true,
      count: 14,
    },
    'CAR-SLI04-CHK': {
      avaliable: true,
      personalisation: true,
      count: 6,
    },
    'CAR-POC01-NAV': {
      avaliable: true,
      personalisation: false,
      count: 5,
    },
    'CAR-POC02-BLA': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'CAR-POC03-FOR': {
      avaliable: true,
      personalisation: false,
      count: 7,
    },
    'CAR-POC04-CHK': {
      avaliable: false,
      personalisation: true,
      count: 2,
    },
    'DBK-WKD01-BLA': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'DBK-WKD02-NAV': {
      avaliable: true,
      personalisation: true,
      count: 3,
    },
    'DBK-WKD03-TAU': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'DBK-BPK01-BLA': {
      avaliable: true,
      personalisation: true,
      count: 20,
    },
    'DBK-BPK02-NAV': {
      avaliable: true,
      personalisation: true,
      count: 19,
    },
    'DBK-BPK03-TAU': {
      avaliable: true,
      personalisation: true,
      count: 21,
    },
    'DBK-TOT01-BLA': {
      avaliable: true,
      personalisation: true,
      count: 7,
    },
    'DBK-TOT02-NAV': {
      avaliable: true,
      personalisation: true,
      count: 7,
    },
    'DBK-TOT03-TAU': {
      avaliable: true,
      personalisation: false,
      count: 7,
    },
    'PB01-BLA': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'REX-PB01-BLA': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'TMA-COPB01-NAV': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'TMA-COPB02-CHA': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'TMA-COPB03-FOR': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'TMA-COPB04-NUD': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'TMA-COPB04-SAN': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'TMA-COPB05-BUR': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'TMA-COPB06-MON': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'TMA-COPB07-CLA': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'TMA-COPB07-CLY': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'TMA-COPB08-SKY': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'TMA-COPB09-SHA': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'TMA-COPB09-SHW': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'TMA-COPB10-GRY': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'TMA-COPB11-OLI': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'TMA-COPB12-PIN': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'TMA-PRO-COPB01-NAV': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'TMA-PRO-COPB02-CHA': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'TMA-PRO-COPB03-FOR': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'TMA-PRO-COPB04-MON': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'LGT-CO1-NAV': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'LGT-CO10-GRY': {
      avaliable: true,
      personalisation: true,
      count: 20,
    },
    'LGT-CO11-OLI': {
      avaliable: true,
      personalisation: true,
      count: 15,
    },
    'LGT-CO12-PIN': {
      avaliable: true,
      personalisation: true,
      count: 19,
    },
    'LGT-CO2-CHA': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'LGT-CO3-FOR': {
      avaliable: true,
      personalisation: true,
      count: 18,
    },
    'LGT-CO4-SAN': {
      avaliable: true,
      personalisation: true,
      count: 25,
    },
    'LGT-CO5-BUR': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'LGT-CO6-MON': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'LGT-CO7-CLA': {
      avaliable: true,
      personalisation: true,
      count: 14,
    },
    'LGT-CO8-SKY': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'LGT-CO9-SHA': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'BOT-LAR01-BLA': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'BOT-LAR02-NAV': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'BOT-LAR05-MOS': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'BOT-LAR06-PIN': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'BOT-LAR07-YEL': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'BOT-LAR08-BLU': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'BOT-LAR11-MAG': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'BOT-LAR12-CBLU': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'BOT-SML01-BLA': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'BOT-SML02-NAV': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'BOT-SML03-LGR': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'BOT-SML04-CLA': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'BOT-SML05-MOS': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'BOT-SML06-PIN': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'BOT-SML07-YEL': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'BOT-SML08-BLU': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'BOT-SML09-MAG': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'BOT-SML10-CBLU': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'MOT-CRD01-GRE': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'MOT-CRD02-BLU': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'MOT-CRD03-RED': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'IPC-MAX01-BLA': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'IPC-MAX02-NAV': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'IPC-MAX03-FOR': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'IPC-MAX04-TAU': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'IPC-MIN01-BLA': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'IPC-MIN02-NAV': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'IPC-MIN03-FOR': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'IPC-MIN04-TAU': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'IPC-PRO01-BLA': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'IPC-PRO02-NAV': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'IPC-PRO03-FOR': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'IPC-PRO04-TAU': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'TRK-COPB01-SAN': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'TRK-COPB02-GRY': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'TRK-COPB03-GRE': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'TRK-COPB04-RED': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'TRK-COPB05-MBLA': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'TRK-COPB06-CBLU': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'TRK-CHK01-SAN': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'TRK-CHK02-GRY': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'TRK-CHK03-GRE': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'TRK-CHK04-RED': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'TRK-CHK05-MBLA': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'TRK-CHK06-CBLU': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'CAR-WKDP01-NAV': {
      avaliable: true,
      personalisation: true,
      count: 9,
    },
    'CAR-WKDP02-BLA': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'CAR-WKDP03-CHK': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    COFFEE: {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'SET-FMLY01-NAV': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'SET-FMLY02-CHA': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'SET-FMLY03-FOR': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'SET-FMLY04-NUD': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'SET-FMLY05-BUR': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'SET-FMLY06-MON': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'SET-FMLY07-CLY': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'SET-FMLY08-SKY': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'SET-FMLY09-SHW': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'SET-FMLY11-OLI': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'SET-FMLY13-YEL': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'SET-FMLY14-BLU': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'SET-TRK-CHKCOPB01-SAN': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'SET-TRK-CHKCOPB02-GRY': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'SET-TRK-CHKCOPB03-GRE': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'SET-CHKCOPB01-NAV': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'SET-CHKCOPB02-CHA': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'SET-CHKCOPB03-FOR': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'SET-CHKCOPB04-NUD': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'SET-CHKCOPB05-BUR': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'SET-CHKCOPB06-MON': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'SET-CHKCOPB07-CLY': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'SET-CHKCOPB08-SKY': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'SET-CHKCOPB09-SHW': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'SET-CHKCOPB10-GRY': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'SET-CHKCOPB11-OLI': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'SET-CHKCOPB12-PIN': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'SET-CHKCOPB13-YEL': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'SET-CHKCOPB14-BLU': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'NBKA5-HARD01-BLA': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'NBKA5-HARD02-SAP': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'NBKA5-HARD03-HYD': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'NBKA5-HARD04-RED': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'NBKA5-HARD05-GRE': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'NBKA5-SOFT01-BLA': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'NBKA5-SOFT02-SAP': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'NBKA5-SOFT03-HYD': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'NBKA5-SOFT04-RED': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'NBKA5-SOFT05-GRE': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'TAG01-BLA': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'TAG02-GRY': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'SET-PLSCOPB01-NAV': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'SET-PLSCOPB02-CHA': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'SET-PLSCOPB03-FOR': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'SET-PLSCOPB04-NUD': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'SET-PLSCOPB05-BUR': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'SET-PLSCOPB06-MON': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'SET-PLSCOPB07-CLY': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'SET-PLSCOPB08-SKY': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'SET-PLSCOPB09-SHW': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'SET-PLSCOPB11-OLI': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'SET-PLSCOPB13-YEL': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'SET-PLSCOPB14-BLU': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'PRO-SLEE01-BLA': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'TEC01-BLA': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'COV-TRKL01-BLA': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'COV-TRKS01-BLA': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'CME21-NAV': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'CME22-CHA': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'CME23-FOR': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'CME24-SAN': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'CME25-CLA': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'CME26-SHA': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'CME27-YEL': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'CME28-BLU': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'CME29-MAG': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'STR01-NAV': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'STR02-CHA': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'STR03-FOR': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'STR04-SAN': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'STR05-CLA': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'STR06-SHA': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'STR07-YEL': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'STR08-BLU': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'STR09-MAG': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'TOY01-BLA': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'TOY02-CLA': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'TOY03-MOS': {
      avaliable: false,
      personalisation: true,
      count: 0,
    },
    'VDAY-JONNY': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'VDAY-LUBE': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
  },
  AU: {
    'COPB01-NAV': {
      avaliable: true,
      personalisation: true,
      count: 170,
    },
    'COPB02-CHA': {
      avaliable: true,
      personalisation: true,
      count: 208,
    },
    'COPB03-FOR': {
      avaliable: true,
      personalisation: true,
      count: 316,
    },
    'COPB04-NUD': {
      avaliable: true,
      personalisation: true,
      count: 289,
    },
    'COPB05-BUR': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'COPB06-MON': {
      avaliable: false,
      personalisation: false,
      count: -1,
    },
    'COPB07-CLA': {
      avaliable: true,
      personalisation: true,
      count: 4,
    },
    'COPB07-CLY': {
      avaliable: true,
      personalisation: true,
      count: 162,
    },
    'COPB08-SKY': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'COPB09-SHA': {
      avaliable: true,
      personalisation: true,
      count: 5,
    },
    'COPB09-SHW': {
      avaliable: true,
      personalisation: true,
      count: 74,
    },
    'COPB10-GRY': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'COPB11-OLI': {
      avaliable: false,
      personalisation: false,
      count: -53,
    },
    'COPB12-PIN': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'COPB13-YEL': {
      avaliable: true,
      personalisation: true,
      count: 164,
    },
    'COPB14-BLU': {
      avaliable: true,
      personalisation: true,
      count: 175,
    },
    'CHK01-NAV': {
      avaliable: true,
      personalisation: true,
      count: 43,
    },
    'CHK02-CHA': {
      avaliable: true,
      personalisation: true,
      count: 156,
    },
    'CHK03-FOR': {
      avaliable: true,
      personalisation: true,
      count: 238,
    },
    'CHK04-NUD': {
      avaliable: true,
      personalisation: true,
      count: 66,
    },
    'CHK05-BUR': {
      avaliable: false,
      personalisation: false,
      count: -1,
    },
    'CHK06-MON': {
      avaliable: false,
      personalisation: false,
      count: -2,
    },
    'CHK07-CLA': {
      avaliable: true,
      personalisation: true,
      count: 25,
    },
    'CHK07-CLY': {
      avaliable: true,
      personalisation: true,
      count: 133,
    },
    'CHK08-SKY': {
      avaliable: false,
      personalisation: false,
      count: 3,
    },
    'CHK09-SHA': {
      avaliable: true,
      personalisation: true,
      count: 5,
    },
    'CHK09-SHW': {
      avaliable: true,
      personalisation: true,
      count: 87,
    },
    'CHK10-GRY': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'CHK11-OLI': {
      avaliable: true,
      personalisation: true,
      count: 25,
    },
    'CHK12-PIN': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'CHK13-YEL': {
      avaliable: true,
      personalisation: true,
      count: 109,
    },
    'CHK14-BLU': {
      avaliable: true,
      personalisation: true,
      count: 137,
    },
    'PLS01-NAV': {
      avaliable: true,
      personalisation: true,
      count: 150,
    },
    'PLS02-CHA': {
      avaliable: true,
      personalisation: true,
      count: 273,
    },
    'PLS03-FOR': {
      avaliable: true,
      personalisation: true,
      count: 165,
    },
    'PLS04-NUD': {
      avaliable: true,
      personalisation: true,
      count: 341,
    },
    'PLS05-BUR': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'PLS06-MON': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'PLS07-CLA': {
      avaliable: true,
      personalisation: true,
      count: 7,
    },
    'PLS07-CLY': {
      avaliable: true,
      personalisation: true,
      count: 58,
    },
    'PLS08-SKY': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'PLS09-SHA': {
      avaliable: true,
      personalisation: true,
      count: 41,
    },
    'PLS09-SHW': {
      avaliable: true,
      personalisation: true,
      count: 120,
    },
    'PLS11-OLI': {
      avaliable: true,
      personalisation: true,
      count: 120,
    },
    'PLS13-YEL': {
      avaliable: true,
      personalisation: true,
      count: 127,
    },
    'PLS14-BLU': {
      avaliable: true,
      personalisation: true,
      count: 188,
    },
    'CME01-NAV': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'CME02-CHA': {
      avaliable: false,
      personalisation: false,
      count: -3,
    },
    'CME03-FOR': {
      avaliable: false,
      personalisation: false,
      count: 1,
    },
    'CME04-NUD': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'CME05-BUR': {
      avaliable: false,
      personalisation: false,
      count: -1,
    },
    'CME06-MON': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'CME07-CLA': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'CME08-SKY': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'CME09-SHA': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'CAR-WKD01-NAV': {
      avaliable: true,
      personalisation: true,
      count: 46,
    },
    'CAR-WKD02-BLA': {
      avaliable: false,
      personalisation: false,
      count: -1,
    },
    'WKD01-NAV': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'WKD02-CHA': {
      avaliable: false,
      personalisation: false,
      count: -2,
    },
    'WKD03-FOR': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'WKD04-NUD': {
      avaliable: false,
      personalisation: false,
      count: -8,
    },
    'BPK01-NAV': {
      avaliable: true,
      personalisation: true,
      count: 1,
    },
    'BPK02-CHA': {
      avaliable: false,
      personalisation: false,
      count: 2,
    },
    'BPK03-FOR': {
      avaliable: false,
      personalisation: false,
      count: -3,
    },
    'BPK04-NUD': {
      avaliable: true,
      personalisation: true,
      count: 61,
    },
    'CAR-BPK01-NAV': {
      avaliable: true,
      personalisation: true,
      count: 34,
    },
    'CAR-BPK02-BLA': {
      avaliable: false,
      personalisation: false,
      count: -11,
    },
    'CAR-BPK02-CHA': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'CAR-TOT01-NAV': {
      avaliable: true,
      personalisation: true,
      count: 121,
    },
    'CAR-TOT02-BLA': {
      avaliable: true,
      personalisation: true,
      count: 117,
    },
    'TOT01-NAV': {
      avaliable: true,
      personalisation: true,
      count: 5,
    },
    'TOT02-CHA': {
      avaliable: true,
      personalisation: true,
      count: 1,
    },
    'TOT03-FOR': {
      avaliable: true,
      personalisation: true,
      count: 51,
    },
    'TOT04-NUD': {
      avaliable: true,
      personalisation: true,
      count: 7,
    },
    '4PC01-SNO': {
      avaliable: true,
      personalisation: true,
      count: 268,
    },
    '4PC02-CHA': {
      avaliable: true,
      personalisation: true,
      count: 116,
    },
    '4PC03-FOR': {
      avaliable: true,
      personalisation: true,
      count: 238,
    },
    '4PC04-CLY': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    '4PC05-SKY': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    '4PC06-SHW': {
      avaliable: false,
      personalisation: false,
      count: 3,
    },
    '8PC01-SNO': {
      avaliable: true,
      personalisation: true,
      count: 268,
    },
    '8PC02-CHA': {
      avaliable: true,
      personalisation: true,
      count: 185,
    },
    '8PC03-FOR': {
      avaliable: true,
      personalisation: true,
      count: 266,
    },
    '8PC04-CLY': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    '8PC05-SKY': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    '8PC06-SHW': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'PRO-COPB01-NAV': {
      avaliable: true,
      personalisation: true,
      count: 243,
    },
    'PRO-COPB02-CHA': {
      avaliable: true,
      personalisation: true,
      count: 229,
    },
    'PRO-COPB03-FOR': {
      avaliable: true,
      personalisation: true,
      count: 196,
    },
    'PRO-COPB04-MON': {
      avaliable: true,
      personalisation: true,
      count: 211,
    },
    'CAR-DPK01-NAV': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'CAR-DPK02-BLA': {
      avaliable: false,
      personalisation: false,
      count: -10,
    },
    'CAR-DPK03-FOR': {
      avaliable: true,
      personalisation: true,
      count: 58,
    },
    'CAR-DPK04-CHK': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'CAR-SLI01-NAV': {
      avaliable: true,
      personalisation: true,
      count: 90,
    },
    'CAR-SLI02-BLA': {
      avaliable: true,
      personalisation: true,
      count: 8,
    },
    'CAR-SLI03-FOR': {
      avaliable: true,
      personalisation: true,
      count: 80,
    },
    'CAR-SLI04-CHK': {
      avaliable: true,
      personalisation: true,
      count: 63,
    },
    'CAR-POC01-NAV': {
      avaliable: false,
      personalisation: false,
      count: -2,
    },
    'CAR-POC02-BLA': {
      avaliable: false,
      personalisation: false,
      count: -1,
    },
    'CAR-POC03-FOR': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'CAR-POC04-CHK': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'DBK-WKD01-BLA': {
      avaliable: true,
      personalisation: true,
      count: 12,
    },
    'DBK-WKD02-NAV': {
      avaliable: true,
      personalisation: true,
      count: 5,
    },
    'DBK-WKD03-TAU': {
      avaliable: false,
      personalisation: false,
      count: -1,
    },
    'DBK-BPK01-BLA': {
      avaliable: true,
      personalisation: true,
      count: 30,
    },
    'DBK-BPK02-NAV': {
      avaliable: true,
      personalisation: true,
      count: 6,
    },
    'DBK-BPK03-TAU': {
      avaliable: true,
      personalisation: true,
      count: 36,
    },
    'DBK-TOT01-BLA': {
      avaliable: true,
      personalisation: true,
      count: 75,
    },
    'DBK-TOT02-NAV': {
      avaliable: true,
      personalisation: true,
      count: 91,
    },
    'DBK-TOT03-TAU': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'PB01-BLA': {
      avaliable: true,
      personalisation: true,
      count: 70,
    },
    'REX-PB01-BLA': {
      avaliable: true,
      personalisation: true,
      count: 56,
    },
    'TMA-COPB01-NAV': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'TMA-COPB02-CHA': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'TMA-COPB03-FOR': {
      avaliable: true,
      personalisation: true,
      count: 16,
    },
    'TMA-COPB04-NUD': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'TMA-COPB04-SAN': {
      avaliable: false,
      personalisation: false,
      count: 2,
    },
    'TMA-COPB05-BUR': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'TMA-COPB06-MON': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'TMA-COPB07-CLA': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'TMA-COPB07-CLY': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'TMA-COPB08-SKY': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'TMA-COPB09-SHA': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'TMA-COPB09-SHW': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'TMA-COPB10-GRY': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'TMA-COPB11-OLI': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'TMA-COPB12-PIN': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'TMA-PRO-COPB01-NAV': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'TMA-PRO-COPB02-CHA': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'TMA-PRO-COPB03-FOR': {
      avaliable: false,
      personalisation: false,
      count: 2,
    },
    'TMA-PRO-COPB04-MON': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'LGT-CO1-NAV': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'LGT-CO10-GRY': {
      avaliable: true,
      personalisation: true,
      count: 86,
    },
    'LGT-CO11-OLI': {
      avaliable: true,
      personalisation: true,
      count: 253,
    },
    'LGT-CO12-PIN': {
      avaliable: true,
      personalisation: true,
      count: 59,
    },
    'LGT-CO2-CHA': {
      avaliable: true,
      personalisation: true,
      count: 104,
    },
    'LGT-CO3-FOR': {
      avaliable: true,
      personalisation: true,
      count: 165,
    },
    'LGT-CO4-SAN': {
      avaliable: true,
      personalisation: true,
      count: 125,
    },
    'LGT-CO5-BUR': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'LGT-CO6-MON': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'LGT-CO7-CLA': {
      avaliable: true,
      personalisation: true,
      count: 135,
    },
    'LGT-CO8-SKY': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'LGT-CO9-SHA': {
      avaliable: true,
      personalisation: true,
      count: 119,
    },
    'BOT-LAR01-BLA': {
      avaliable: true,
      personalisation: true,
      count: 275,
    },
    'BOT-LAR02-NAV': {
      avaliable: true,
      personalisation: true,
      count: 475,
    },
    'BOT-LAR05-MOS': {
      avaliable: true,
      personalisation: true,
      count: 267,
    },
    'BOT-LAR06-PIN': {
      avaliable: true,
      personalisation: true,
      count: 100,
    },
    'BOT-LAR07-YEL': {
      avaliable: true,
      personalisation: true,
      count: 197,
    },
    'BOT-LAR08-BLU': {
      avaliable: true,
      personalisation: true,
      count: 97,
    },
    'BOT-LAR11-MAG': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'BOT-LAR12-CBLU': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'BOT-SML01-BLA': {
      avaliable: true,
      personalisation: true,
      count: 389,
    },
    'BOT-SML02-NAV': {
      avaliable: true,
      personalisation: true,
      count: 676,
    },
    'BOT-SML03-LGR': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'BOT-SML04-CLA': {
      avaliable: false,
      personalisation: false,
      count: -1,
    },
    'BOT-SML05-MOS': {
      avaliable: true,
      personalisation: true,
      count: 220,
    },
    'BOT-SML06-PIN': {
      avaliable: true,
      personalisation: true,
      count: 45,
    },
    'BOT-SML07-YEL': {
      avaliable: true,
      personalisation: true,
      count: 196,
    },
    'BOT-SML08-BLU': {
      avaliable: true,
      personalisation: true,
      count: 199,
    },
    'BOT-SML09-MAG': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'BOT-SML10-CBLU': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'MOT-CRD01-GRE': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'MOT-CRD02-BLU': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'MOT-CRD03-RED': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'IPC-MAX01-BLA': {
      avaliable: true,
      personalisation: true,
      count: 19,
    },
    'IPC-MAX02-NAV': {
      avaliable: true,
      personalisation: true,
      count: 8,
    },
    'IPC-MAX03-FOR': {
      avaliable: false,
      personalisation: false,
      count: 1,
    },
    'IPC-MAX04-TAU': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'IPC-MIN01-BLA': {
      avaliable: true,
      personalisation: true,
      count: 12,
    },
    'IPC-MIN02-NAV': {
      avaliable: true,
      personalisation: true,
      count: 20,
    },
    'IPC-MIN03-FOR': {
      avaliable: true,
      personalisation: true,
      count: 21,
    },
    'IPC-MIN04-TAU': {
      avaliable: true,
      personalisation: true,
      count: 23,
    },
    'IPC-PRO01-BLA': {
      avaliable: true,
      personalisation: true,
      count: 27,
    },
    'IPC-PRO02-NAV': {
      avaliable: false,
      personalisation: false,
      count: -1,
    },
    'IPC-PRO03-FOR': {
      avaliable: true,
      personalisation: true,
      count: 38,
    },
    'IPC-PRO04-TAU': {
      avaliable: true,
      personalisation: true,
      count: 7,
    },
    'TRK-COPB01-SAN': {
      avaliable: true,
      personalisation: true,
      count: 516,
    },
    'TRK-COPB02-GRY': {
      avaliable: true,
      personalisation: true,
      count: 101,
    },
    'TRK-COPB03-GRE': {
      avaliable: true,
      personalisation: true,
      count: 495,
    },
    'TRK-COPB04-RED': {
      avaliable: true,
      personalisation: true,
      count: 45,
    },
    'TRK-COPB05-MBLA': {
      avaliable: true,
      personalisation: true,
      count: 216,
    },
    'TRK-COPB06-CBLU': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'TRK-CHK01-SAN': {
      avaliable: true,
      personalisation: true,
      count: 473,
    },
    'TRK-CHK02-GRY': {
      avaliable: true,
      personalisation: true,
      count: 15,
    },
    'TRK-CHK03-GRE': {
      avaliable: true,
      personalisation: true,
      count: 458,
    },
    'TRK-CHK04-RED': {
      avaliable: true,
      personalisation: true,
      count: 114,
    },
    'TRK-CHK05-MBLA': {
      avaliable: true,
      personalisation: true,
      count: 195,
    },
    'TRK-CHK06-CBLU': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'CAR-WKDP01-NAV': {
      avaliable: true,
      personalisation: true,
      count: 45,
    },
    'CAR-WKDP02-BLA': {
      avaliable: true,
      personalisation: true,
      count: 10,
    },
    'CAR-WKDP03-CHK': {
      avaliable: false,
      personalisation: false,
      count: -3,
    },
    COFFEE: {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'SET-FMLY01-NAV': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'SET-FMLY02-CHA': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'SET-FMLY03-FOR': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'SET-FMLY04-NUD': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'SET-FMLY05-BUR': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'SET-FMLY06-MON': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'SET-FMLY07-CLY': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'SET-FMLY08-SKY': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'SET-FMLY09-SHW': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'SET-FMLY11-OLI': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'SET-FMLY13-YEL': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'SET-FMLY14-BLU': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'SET-TRK-CHKCOPB01-SAN': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'SET-TRK-CHKCOPB02-GRY': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'SET-TRK-CHKCOPB03-GRE': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'SET-CHKCOPB01-NAV': {
      avaliable: true,
      personalisation: true,
      count: 59,
    },
    'SET-CHKCOPB02-CHA': {
      avaliable: true,
      personalisation: true,
      count: 55,
    },
    'SET-CHKCOPB03-FOR': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'SET-CHKCOPB04-NUD': {
      avaliable: true,
      personalisation: true,
      count: 104,
    },
    'SET-CHKCOPB05-BUR': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'SET-CHKCOPB06-MON': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'SET-CHKCOPB07-CLY': {
      avaliable: true,
      personalisation: true,
      count: 22,
    },
    'SET-CHKCOPB08-SKY': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'SET-CHKCOPB09-SHW': {
      avaliable: true,
      personalisation: true,
      count: 68,
    },
    'SET-CHKCOPB10-GRY': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'SET-CHKCOPB11-OLI': {
      avaliable: true,
      personalisation: true,
      count: 32,
    },
    'SET-CHKCOPB12-PIN': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'SET-CHKCOPB13-YEL': {
      avaliable: true,
      personalisation: true,
      count: 50,
    },
    'SET-CHKCOPB14-BLU': {
      avaliable: true,
      personalisation: true,
      count: 48,
    },
    'NBKA5-HARD01-BLA': {
      avaliable: false,
      personalisation: false,
      count: -72,
    },
    'NBKA5-HARD02-SAP': {
      avaliable: true,
      personalisation: true,
      count: 19,
    },
    'NBKA5-HARD03-HYD': {
      avaliable: true,
      personalisation: true,
      count: 20,
    },
    'NBKA5-HARD04-RED': {
      avaliable: true,
      personalisation: true,
      count: 12,
    },
    'NBKA5-HARD05-GRE': {
      avaliable: true,
      personalisation: true,
      count: 8,
    },
    'NBKA5-SOFT01-BLA': {
      avaliable: true,
      personalisation: true,
      count: 6,
    },
    'NBKA5-SOFT02-SAP': {
      avaliable: true,
      personalisation: true,
      count: 23,
    },
    'NBKA5-SOFT03-HYD': {
      avaliable: true,
      personalisation: true,
      count: 12,
    },
    'NBKA5-SOFT04-RED': {
      avaliable: true,
      personalisation: true,
      count: 17,
    },
    'NBKA5-SOFT05-GRE': {
      avaliable: true,
      personalisation: true,
      count: 20,
    },
    'TAG01-BLA': {
      avaliable: true,
      personalisation: true,
      count: 478,
    },
    'TAG02-GRY': {
      avaliable: true,
      personalisation: true,
      count: 121,
    },
    'SET-PLSCOPB01-NAV': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'SET-PLSCOPB02-CHA': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'SET-PLSCOPB03-FOR': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'SET-PLSCOPB04-NUD': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'SET-PLSCOPB05-BUR': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'SET-PLSCOPB06-MON': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'SET-PLSCOPB07-CLY': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'SET-PLSCOPB08-SKY': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'SET-PLSCOPB09-SHW': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'SET-PLSCOPB11-OLI': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'SET-PLSCOPB13-YEL': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'SET-PLSCOPB14-BLU': {
      avaliable: true,
      personalisation: true,
      count: 0,
    },
    'PRO-SLEE01-BLA': {
      avaliable: true,
      personalisation: true,
      count: 16,
    },
    'TEC01-BLA': {
      avaliable: false,
      personalisation: false,
      count: -35,
    },
    'COV-TRKL01-BLA': {
      avaliable: true,
      personalisation: true,
      count: 39,
    },
    'COV-TRKS01-BLA': {
      avaliable: false,
      personalisation: false,
      count: -4,
    },
    'CME21-NAV': {
      avaliable: true,
      personalisation: true,
      count: 83,
    },
    'CME22-CHA': {
      avaliable: true,
      personalisation: true,
      count: 12,
    },
    'CME23-FOR': {
      avaliable: true,
      personalisation: true,
      count: 89,
    },
    'CME24-SAN': {
      avaliable: true,
      personalisation: true,
      count: 80,
    },
    'CME25-CLA': {
      avaliable: true,
      personalisation: true,
      count: 94,
    },
    'CME26-SHA': {
      avaliable: true,
      personalisation: true,
      count: 76,
    },
    'CME27-YEL': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'CME28-BLU': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'CME29-MAG': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'STR01-NAV': {
      avaliable: true,
      personalisation: true,
      count: 106,
    },
    'STR02-CHA': {
      avaliable: true,
      personalisation: true,
      count: 23,
    },
    'STR03-FOR': {
      avaliable: true,
      personalisation: true,
      count: 104,
    },
    'STR04-SAN': {
      avaliable: true,
      personalisation: true,
      count: 89,
    },
    'STR05-CLA': {
      avaliable: true,
      personalisation: true,
      count: 97,
    },
    'STR06-SHA': {
      avaliable: true,
      personalisation: true,
      count: 142,
    },
    'STR07-YEL': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'STR08-BLU': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'STR09-MAG': {
      avaliable: false,
      personalisation: false,
      count: 0,
    },
    'TOY01-BLA': {
      avaliable: true,
      personalisation: true,
      count: 234,
    },
    'TOY02-CLA': {
      avaliable: true,
      personalisation: true,
      count: 272,
    },
    'TOY03-MOS': {
      avaliable: false,
      personalisation: false,
      count: -11,
    },
    'VDAY-JONNY': {
      avaliable: true,
      personalisation: true,
      count: 178,
    },
    'VDAY-LUBE': {
      avaliable: true,
      personalisation: true,
      count: 99,
    },
  },
}

const client_au = () =>
  Client.buildClient({
    storefrontAccessToken: process.env.SHOPIFY_ACCESS_TOKEN,
    domain: `checkout.july.com`,
  })

const client_us = () =>
  Client.buildClient({
    storefrontAccessToken: process.env.SHOPIFY_ACCESS_TOKEN_US,
    domain: `us-checkout.july.com`,
  })

const clients = {
  'july-travel': client_au,
  'july-us': client_us,
}

function sleep(time) {
  return new Promise((resolve) => setTimeout(resolve, time))
}

export const MenuRight = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  height: 100%;
  background: white;
  transition: all 0.2s ease-out;
  z-index: 9999999;
  box-shadow: -3px 0 5px 0 #55555588;
  overflow: scroll;

  right: ${(props) => (props.isOpen ? '0' : 'calc(-100vw - 100px)')};
  width: 100vw;

  @media (min-width: 1025px) {
    right: ${(props) => (props.isOpen ? '0' : 'calc(-350px - 100px)')};
    width: 350px;
  }
`

export const MainContainer = styled.div`
  background: white;
`

const getLocal = (url) => {
  const path = url.split('/')
  var data = {
    path: 'root',
    locale: 'English',
    default: true,
    currency: 'AUD',
    store: 'july-travel',
    content: 'content',
  }
  if (path.length > 1 && Object.keys(locales).includes(path[1])) {
    return locales[path[1]]
  }
  return data
}

const getInitialLocation = (props) => {
  return getLocal(props.location.pathname).store
}

const getClient = (props) => {
  return clients[getInitialLocation(props)]()
}

const getInitialLanguage = (props) => {
  const locale = getLocal(props.location.pathname)
  if (locale.path === 'root') {
    return 'root'
  }
  return locale.path
}

class Layout extends React.Component {
  state = {
    store: {
      ...defaultStoreContext,
      menuOpen: false,
      showchat: false,
      cartOpen: false,
      popupOpen: false,
      scroll: true,
      dicount: null,
      currency: getInitialLocation(this.props),
      language: getInitialLanguage(this.props),
      client: getClient(this.props),
      changeLocale: null,
      packingColor: 'any',
      includesGift: false,
      showMother: true,
      oosData: OOSData,
      updatePackingColor: (value) => {
        this.setState((state) => ({
          store: {
            ...state.store,
            packingColor: value,
          },
        }))

        const { checkout, client } = this.state.store
        const checkoutId = checkout.id
        const lineItems = checkout.lineItems
          .map((lineItem) => {
            if (lineItem.variant.price < 195 || lineItem.variant.sku === '') {
              return undefined
            }
            let attributes = [
              ...lineItem.customAttributes.map((att) => ({
                key: att.key,
                value: att.value,
              })),
            ]
            attributes.filter((val) => val.key != 'packing-cells')
            attributes.push({
              key: 'packing-cells',
              value: value,
            })
            return {
              id: lineItem.id,
              quantity: lineItem.quantity,
              customAttributes: attributes,
            }
          })
          .filter((el) => el != null)

        client.checkout.updateLineItems(checkoutId, lineItems).then((res) => {
          this.setState((state) => ({
            store: {
              ...state.store,
              checkout: res,
            },
          }))
        })
      },
      getOOSData: () => {
        const locale = this.state.store.language === 'au' ? 'AU' : 'US'
        return this.state.store.oosData[locale]
      },
      skuOOS: (sku) => {
        return this.state.store.getOOSData()[sku]
      },
      setShowMothersDay: (value) => {
        this.setState((state) => ({
          store: {
            ...state.store,
            showMothersDay: value,
          },
        }))
      },
      setEditing: (value) => {
        this.setState((state) => ({
          store: {
            ...state.store,
            editing: value,
          },
        }))
      },
      setPopupOpen: (value) => {
        this.setState((state) => ({
          store: {
            ...state.store,
            popupOpen: value,
          },
        }))
      },
      setShowChat: (value) => {
        this.setState((state) => ({
          store: {
            ...state.store,
            showChat: value,
          },
        }))
      },
      setDiscount: (value) => {
        this.setState((state) => ({
          store: {
            ...state.store,
            discount: value,
          },
        }))
      },
      setCurrency: (val) => {
        this.setState((state) => ({
          store: {
            ...state.store,
            currency: val,
            client: clients[val],
          },
        }))
      },
      setLanguage: (val) => {
        this.setState((state) => ({
          store: {
            ...state.store,
            language: val,
          },
        }))
      },
      setScroll: (val) => {
        this.setState((state) => ({
          store: {
            ...state.store,
            scroll: val,
          },
        }))
      },
      setSelectedColor: (val) => {
        this.setState((state) => ({
          store: {
            ...state.store,
            selectedColor: val,
          },
        }))
      },
      setSelectedCase: (val) => {
        this.setState((state) => ({
          store: {
            ...state.store,
            selectedCase: val,
          },
        }))
      },
      openCart: () => {
        this.setState((state) => ({
          store: {
            ...state.store,
            menuOpen: state.menuOpen,
            cartOpen: true,
          },
        }))
      },
      closeCart: () => {
        this.setState((state) => ({
          store: {
            ...state.store,
            menuOpen: state.menuOpen,
            cartOpen: false,
          },
        }))
      },
      openMenu: () => {
        this.setState((state) => ({
          store: {
            ...state.store,
            menuOpen: true,
            cartOpen: state.cartOpen,
          },
        }))
      },
      closeMenu: () => {
        this.setState((state) => ({
          store: {
            ...state.store,
            menuOpen: false,
            cartOpen: state.cartOpen,
          },
        }))
      },
      setAccColor: (val) => {
        this.setState((state) => ({
          store: {
            ...state.store,
            accColor: val,
          },
        }))
      },
      addDiscountCode: async (discountCode) => {
        const { checkout, client } = this.state.store
        const checkoutId = checkout.id
        client.checkout
          .addDiscount(checkoutId, discountCode)
          .then((res) => {
            this.setState((state) => ({
              store: {
                ...state.store,
                checkout: res,
              },
            }))
          })
          .catch((e) => {
            console.log(e)
          })
      },
      removeDiscountCode: () => {
        const { checkout, client } = this.state.store
        const checkoutId = checkout.id

        client.checkout.removeDiscount(checkoutId).then((res) => {
          this.setState((state) => ({
            store: {
              ...state.store,
              checkout: res,
            },
          }))
        })
      },
      hasWellKit: () => {
        const { checkout } = this.state.store
        let wellKit = false
        checkout.lineItems.map((line_item) => {
          if (line_item.title === 'Travel Well Kit') {
            wellKit = true
          }
          return ''
        })
        return wellKit
      },
      applyDiscountCode: async (checkout) => {
        let showMothers = true
        checkout.lineItems.map((line_item) => {
          if (line_item.variant.sku.includes('VDAY')) {
            showMothers = false
          }
        })
        this.state.store.setShowMothersDay(showMothers)
        await sleep(500)
        let carry,
          checked,
          checkedPlus,
          carryAll = false

        checkout.lineItems.map((line_item) => {
          if (
            line_item.title === 'The July Carry On' ||
            line_item.title === 'Carry On Pro' ||
            line_item.title === 'Carry On Light' ||
            line_item.title === 'Carry On Trunk'
          ) {
            carry = true
          }
          if (
            line_item.title === 'The July Checked Bag' ||
            line_item.title === 'Checked Trunk'
          ) {
            checked = true
          }
          if (line_item.title === 'The July Checked Plus') {
            checkedPlus = true
          }
          if (
            line_item.title === 'Carry All Backpack' ||
            line_item.title === 'Carry All Tote' ||
            line_item.title === 'Carry All Weekender' ||
            line_item.title === 'Carry All Daypack' ||
            line_item.title === 'Daybreak Backpack' ||
            line_item.title === 'Daybreak Weekender'
          ) {
            carryAll = true
          }
          return undefined
        })
        if (checkedPlus && checked && carry) {
          await this.state.store.addDiscountCode('8X4RYG2G')
        } else if (carry && checked) {
          await this.state.store.addDiscountCode('UEQV4E2H')
        } else if (carry && checkedPlus) {
          await this.state.store.addDiscountCode('C6WSDHNC')
        } else if (
          (carry && carryAll) ||
          (checked && carryAll) ||
          (checkedPlus && carryAll)
        ) {
          await this.state.store.addDiscountCode('C6WSDHNC')
        } else if (this.state.store.discount != null) {
          await this.state.store.addDiscountCode(this.state.store.discount)
        } else {
          await this.state.store.removeDiscountCode()
        }
      },
      addVariantToCart: async (
        variantId,
        quantity,
        labelData = {},
        rawData,
        customAttId
      ) => {
        const customAttributes = rawData
          ? rawData
          : [
              ...Object.keys(labelData).map((key) => {
                const value = labelData[key]
                const object = {
                  key,
                  value,
                }
                if (value === '') return undefined
                return object
              }),
            ]
        try {
          if (variantId === [] || variantId === '' || !quantity) {
            console.error('Both a size and quantity are required.')
            return
          }

          this.setState((state) => ({
            store: {
              ...state.store,
              adding: true,
            },
          }))

          const { checkout, client } = this.state.store
          const checkoutId = checkout.id

          let lineItemsToUpdate = []

          if (variantId instanceof Array) {
            lineItemsToUpdate = variantId.map((val, key) => {
              return {
                ...(customAttId === undefined || customAttId.includes(key)
                  ? { customAttributes }
                  : { customAttributes: [] }),
                variantId: val,
                quantity: parseInt(quantity, 10),
              }
            })
          } else {
            lineItemsToUpdate = [
              {
                customAttributes,
                variantId,
                quantity: parseInt(quantity, 10),
              },
            ]
          }
          const newCheckout = await client.checkout.addLineItems(
            checkoutId,
            lineItemsToUpdate
          )
          this.state.store.applyDiscountCode(newCheckout)
          let includesGift = false
          newCheckout.lineItems.map((lineItem) => {
            if (lineItem.variant.price >= 195) {
              includesGift = true
            }
          })
          this.setState((state) => ({
            store: {
              ...state.store,
              checkout: newCheckout,
              includesGift,
              adding: false,
            },
          }))
          return true
        } catch (e) {
          console.log(e)
        }
      },
      removeLineItem: (client, checkoutID, lineItemID) => {
        return client.checkout
          .removeLineItems(checkoutID, [lineItemID])
          .then((res) => {
            this.state.store.applyDiscountCode(res)
            let includesGift = false
            res.lineItems.map((lineItem) => {
              if (lineItem.variant.price >= 195) {
                includesGift = true
              }
            })
            if (
              res.lineItems.length === 1 &&
              res.lineItems
                .map((lineItem) => lineItem.variant.sku.includes('VDAY'))
                .reduce((sum, val) => sum || val, false)
            ) {
              this.state.store.removeLineItem(
                client,
                checkoutID,
                res.lineItems.find((val) => val.variant.sku.includes('VDAY')).id
              )
            }
            this.setState((state) => ({
              store: {
                ...state.store,
                checkout: res,
                includesGift,
              },
            }))
          })
      },
      updateLineItem: (client, checkoutID, lineItemID, quantity) => {
        const lineItemsToUpdate = [
          { id: lineItemID, quantity: parseInt(quantity, 10) },
        ]

        return client.checkout
          .updateLineItems(checkoutID, lineItemsToUpdate)
          .then((res) => {
            this.state.store.applyDiscountCode(res)
            this.setState((state) => ({
              store: {
                ...state.store,
                checkout: res,
              },
            }))
          })
      },
      applySiteDiscount: async () => {
        if (this.state.store.discount != null) {
          this.state.store.addDiscountCode(this.state.store.discount)
        }
      },
      initializeCheckout: async () => {
        // Check for an existing cart.
        const isBrowser = typeof window !== 'undefined'
        const existingCheckoutID = isBrowser
          ? localStorage.getItem('shopify_checkout_id')
          : null

        const setCheckoutInState = (checkout) => {
          // if (window.GorgiasChat){
          //   window.GorgiasChat.captureShopifyCart(checkout);
          // }

          if (typeof window !== `undefined` && window.r) {
            const checkoutUrl = checkout.webUrl.split('/')
            const token = checkoutUrl[checkoutUrl.length - 1].split('?')
            const checkout_token = token[0]

            const rfsn = {
              cart: checkout_token,
              id: localStorage.getItem('rfsn_v4_id'),
              url: window.location.href,
              aid: localStorage.getItem('rfsn_v4_aid'),
              cs: localStorage.getItem('rfsn_v4_cs'),
            }
            window.r.sendCheckoutEvent(
              rfsn.cart,
              rfsn.id,
              rfsn.url,
              rfsn.aid,
              rfsn.cs
            )
          }
          if (isBrowser) {
            localStorage.setItem('shopify_checkout_id', checkout.id)
          }
          let includesGift = false
          let showMothers = true

          checkout.lineItems.map((lineItem) => {
            if (lineItem.variant.price >= 195) {
              includesGift = true
            }
            if (lineItem.variant.sku.includes('VDAY')) {
              showMothers = false
            }
          })
          this.setState((state) => ({
            store: {
              ...state.store,
              includesGift,
              checkout,
            },
          }))
          this.state.store.setShowMothersDay(showMothers)
        }

        const createNewCheckout = () =>
          this.state.store.client.checkout.create()
        const fetchCheckout = (id) => this.state.store.client.checkout.fetch(id)

        if (existingCheckoutID) {
          try {
            const checkout = await fetchCheckout(existingCheckoutID)

            // Make sure this cart hasn’t already been purchased.
            if (!checkout.completedAt) {
              setCheckoutInState(checkout)
              return
            }
          } catch (e) {
            localStorage.setItem('shopify_checkout_id', null)
          }
        }

        const newCheckout = await createNewCheckout()

        setCheckoutInState(newCheckout)
      },
    },
  }

  componentDidMount() {
    this.state.store.initializeCheckout()

    // see http://ip-api.com/docs/api:json for documentation
    var endpoint =
      'https://pro.ip-api.com/json/?fields=status,message,countryCode&key=yPBaM6lJ93dxahZ'
    let self = this
    var xhr = new XMLHttpRequest()
    xhr.onreadystatechange = function () {
      if (this.readyState == 4 && this.status == 200) {
        var response = JSON.parse(this.responseText)
        if (response.status !== 'success') {
          return
        }
        const loc = getInitialLocation(window).toUpperCase()
        const lang = getInitialLanguage(window).toUpperCase()
        const ipLoc = response.countryCode.toUpperCase()

        // if(ipLoc === 'SG' && (loc !== 'SG' || lang === 'ROOT')) {
        //   self.setState(state => ({
        //     store: {
        //       ...state.store,
        //       changeLocale: 'Singapore',
        //     },
        //   }))
        // }
        if (ipLoc === 'AU' && loc !== 'JULY-TRAVEL' && lang !== 'AU') {
          self.setState((state) => ({
            store: {
              ...state.store,
              changeLocale: 'Australia',
            },
          }))
        }
        if ((ipLoc === 'US' || ipLoc === 'CA') && lang !== 'US') {
          self.setState((state) => ({
            store: {
              ...state.store,
              changeLocale: 'North America',
            },
          }))
        }
        // if(ipLoc === 'NZ' && (loc !== 'NZ' || lang === 'ROOT')) {
        //   self.setState(state => ({
        //     store: {
        //       ...state.store,
        //       changeLocale: 'New Zealand',
        //     },
        //   }))
        // }
      }
    }
    xhr.open('GET', endpoint, true)
    xhr.send()

    const search = queryString.parse(this.props.location.search)
    const keys = Object.keys(search)

    if (search['cfclick']) {
      localStorage.setItem('cfclick', search['cfclick'])
    }

    this.state.store.setDiscount(search['discount'])
    this.state.store.addDiscountCode(search['discount'])

    const getOOS = async () => {
      try {
        const [AU, US] = await Promise.all([
          new Promise((res, rej) => {
            fetch('https://july.com/stock/AU.json')
              .then((response) => {
                return response.json()
              })
              .then((data) => {
                return res(data)
              })
              .catch((e) => rej(e))
          }),
          new Promise((res, rej) => {
            fetch('https://july.com/stock/US.json')
              .then((response) => {
                return response.json()
              })
              .then((data) => {
                return res(data)
              })
              .catch((e) => rej(e))
          }),
        ])
        this.setState((state) => ({
          store: {
            ...state.store,
            oosData: { AU, US },
          },
        }))
      } catch (e) {
        console.log(e)
      }
    }
    getOOS()
    setInterval(getOOS, 1000 * 60 * 2)

    const colorsSuitcases = {
      navy: 0,
      charcoal: 1,
      forest: 2,
      nude: 3,
      burgundy: 4,
      monochrome: 5,
      clay: 6,
      sky: 7,
      shadow: 8,
    }
    const names = Object.keys(colorsSuitcases)
    keys.map((val) => {
      if (names.includes(val)) {
        this.state.store.setSelectedColor(colorsSuitcases[val])
      }
      return undefined
    })

    const caseSizes = {
      carry: 0,
      checked: 1,
      plus: 2,
      family: 3,
    }
    const sizes = Object.keys(caseSizes)
    keys.map((val) => {
      if (sizes.includes(val)) {
        this.state.store.setSelectedCase(caseSizes[val])
      }
      return undefined
    })
  }

  render() {
    const { children } = this.props
    const handleLocaleChange = () => {
      const stores = {
        Singapore: 'sg',
        Australia: 'au',
        'New Zealand': 'nz',
        'North America': 'us',
      }
      const root = this.state.store.language === 'root'
      const locale = `${stores[this.state.store.changeLocale]}`
      var pathname = this.props.location.pathname
      if (root) {
        window.location.href = `/${locale}${pathname === '/' ? '' : pathname}/${
          this.props.location.search
        }`
      } else {
        const shortPath = pathname.substr(3)
        window.location.href = `/${locale}${shortPath}${this.props.location.search}`
      }
    }
    const handleClose = () => {
      this.setState((state) => ({
        store: {
          ...state.store,
          changeLocale: '',
        },
      }))
    }
    const visible = !(
      this.state.store.changeLocale === null ||
      this.state.store.changeLocale === ''
    )
    return (
      <StoreContext.Provider value={this.state.store}>
        <div>{children}</div>
        <div
          style={{
            opacity: visible ? 1 : 0,
            display: visible ? 'flex' : 'none',
          }}
          className="absolute-bottom-banner"
        >
          <div />
          <div
            className="absolute-banner-text"
            role="button"
            tabIndex={0}
            onKeyDown={handleLocaleChange}
            onClick={handleLocaleChange}
          >
            It looks like you are browsing from {this.state.store.changeLocale}.{' '}
            <strong>
              Click here to navigate to our {this.state.store.changeLocale}{' '}
              site.
            </strong>
          </div>
          <div
            className="absolute-close-buttom"
            role="button"
            tabIndex={0}
            onKeyDown={handleClose}
            onClick={handleClose}
          >
            Close
          </div>
        </div>
        {this.state.store.editing !== null && (
          <div
            style={{
              width: '100vw',
              height: '100vh',
              background: '#00000077',
              position: 'fixed',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 9999999999,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                width: '400px',
                height: 400,
                background: 'white',
              }}
            >
              <div
                role="button"
                tabIndex={0}
                onKeyDown={() => this.state.store.setEditing(null)}
                onClick={() => this.state.store.setEditing(null)}
              >
                close
              </div>
              {this.state.store.editing}
            </div>
          </div>
        )}
      </StoreContext.Provider>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
